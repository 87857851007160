import React, {useEffect, useRef, useState} from "react"
import Seo from "../components/layout/Seo"
import { StaticImage } from "gatsby-plugin-image";

import {motion, MotionConfig} from 'framer-motion'
import {InView} from 'react-intersection-observer'

import { Link } from 'gatsby'

import InPageNav from "../components/components/InPageNav"; 
import { SuperTitle, SectionTitle, HeroSubtitle, HeroTitle } from '../components/components/PageElements'

import { sectionVar, fadeEast, fadeWest} from '../data/transitions'

import { aboutHolder } from "./About.module.scss"
import { heroHolder } from "../components/components/PageElements.module.scss"


// markup
const AboutPage = () => {
  const ourCompany = useRef()
  const ourApproach = useRef()
  const ourInvestments = useRef()

  const [sections, setSections] = useState([])


  useEffect(()=>{

    setSections(
    [{
      id:'our-company',
      title:'Our Company',
      ref:ourCompany.current
    },
    {
      id:'our-approach',
      title:'Our Approach',
      ref:ourApproach.current
    },
    { 
      id:'our-investments',
      title:'Our Investments',
      ref:ourInvestments.current
    }]
    )

  },[])



  return (
    <MotionConfig transition={{duration:0.7, type:"tween"}}>
    <article className={aboutHolder} >
      <Seo title="Pygmalion Capital Ltd" description="Dedicated Hotel Investing" />
      <InPageNav sections={sections} pageTitle="about"/>
      <section className={heroHolder}>
        <div className="lrHolder">
          <div className="left">
            <StaticImage
              src="../images/pages/about/about-hero.jpg"
              alt="Pygmalion Capital - About Pygmalion Capital"
              quality={80}
              width={800}
              className="roundedCorners"
            />
          </div>
          <div className="right">
            <div className="inner">
              <SuperTitle title="Introduction" inView={true}/>
              <HeroTitle>About Pygmalion Capital</HeroTitle>
              <HeroSubtitle>Pygmalion Capital Advisers LLP is an investment firm exclusively focused on European hotel investing.</HeroSubtitle>
            </div>
          </div>
        </div>
      </section>

      <InView threshold={0.25} triggerOnce={true}>
      {({ref, inView}) => (
      <section id="our-company" ref={ourCompany}>
        <motion.div ref={ref} variants={sectionVar} initial="i" animate={inView ? 'v' : 'i'} exit="o" className="lrHolder">
          <motion.div className="left" variants={fadeEast} >
            <StaticImage
              src="../images/pages/about/our-company.jpg"
              alt="Pygmalion Capital - Our Company"
              quality={80}
              width={1200}
            />
          </motion.div>  
          <motion.div className="right" variants={fadeWest} >
            <div className="inner">
              <SuperTitle title="Who We Are" inView={true} />
              <SectionTitle>Our company</SectionTitle>
              <p>Pygmalion was formed in 2017 by former Goldman Sachs banker Christophe Beauvilain. He was previously partner at Perenne Capital LLP from 2010, which provided deal sourcing and structuring in the European hotel sector.</p>
              <p>Pygmalion created its first investment vehicle in 2017, with capital from CBRE Global Investment Partners (CBRE GIP) and several European pension funds, to acquire and reposition special situation hotel assets across Europe.</p>
              <p>Pygmalion’s second investment vehicle, Pygmalion European Opportunistic Hotel Fund II, also seeks European opportunistic hotel investments. It aims to unlock value from complex situations, which offer both a strong discount at entry and significant expansion potential.</p>
            </div>
          </motion.div>
        </motion.div>
      </section>
      )}
      </InView>

      <InView threshold={0.25} triggerOnce={true}>
      {({ref, inView}) => (
      <section id="our-approach" ref={ourApproach}>
        <motion.div ref={ref} variants={sectionVar} initial="i" animate={inView ? 'v' : 'i'} exit="o" className="lrHolder">

        <motion.div className="left" variants={fadeEast} >
            <StaticImage
              src="../images/pages/about/our-approach.jpg"
              alt="Pygmalion Capital - Our Approach"
              placeholder="tracedSVG"
              quality={80}
              width={1200}
            />
          </motion.div>  
          <motion.div className="right" variants={fadeWest} >
            <div className="inner">
              <SuperTitle title="How we work" inView={true} />
              <SectionTitle>Our approach</SectionTitle>
              <p>Pygmalion dedicates considerable resources to the upfront financial, tax, commercial, legal and technical due diligence, to deal structuring and negotiation, and to the individual asset management strategy.</p>
              <p>Pygmalion’s special situations investment strategy is based on sourcing and unlocking value from undervalued, illiquid or complex situations (including distressed opportunities) offering both a strong discount at entry and post-CapEx NOI growth through efficient operating of the underlying assets.</p>
              <p>The Pygmalion team maintains a network of strong, experienced hotel operators.  These are both local white-label hotel operators, who are leaders in their domestic markets and international operators who are keen to increase their presence in certain under-penetrated European markets.</p>
            </div>
          </motion.div>
        </motion.div>
      </section>
      )}
      </InView>

      <InView threshold={0.25} triggerOnce={true}>
      {({ref, inView}) => (
      <section id="our-investments" ref={ourInvestments}>
        <motion.div ref={ref} variants={sectionVar} initial="i" animate={inView ? 'v' : 'i'} exit="o" className="lrHolder">
          <motion.div className="left" variants={fadeEast} >
            <StaticImage
              src="../images/pages/about/our-investments.jpg"
              alt="Pygmalion Capital - Our Investments"
              quality={80}
              width={1200}
              className="roundedCorners"
            />
          </motion.div>  
          <motion.div className="right" variants={fadeWest} >
            <div className="inner">
              <SuperTitle title="How we invest" inView={true} />
              <SectionTitle>Our investments</SectionTitle>
              <p>Further information regarding a selection of our investments can be found in our case studies section.</p>
              <div className="buttonHolder"><Link to="/case-studies" title="Case Studies" className="softButton">Case studies</Link></div>

           </div>
          </motion.div>
        </motion.div>
      </section>
      )}
      </InView>

    </article>
    </MotionConfig>
  )
}

export default AboutPage
