import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React, {useEffect, useState} from 'react'
import {inPageNavHolder, progressBarHolder} from './InPageNav.module.scss'
import LogoOhm from '../../images/svgs/LogoOhm.svg'
import useScrollY from '../../hooks/useScrollY'


// function lerp (start, end, amt){
//   return (1-amt)*start+amt*end
// }

const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));

const invLerp = (x, y, a) => clamp((a - x) / (y - x));


const ProgressBar = ({progress}) => {
  return <div className={progressBarHolder}>
    <div className="progress" style={{width: `${progress}%`}}></div>
  </div>
}


export default function InPageNav({sections, pageTitle}) {

  const scrollY = useScrollY()
  const [sects, setSects] = useState(...sections)
  const [topOffset, setTopOffset] = useState(0)

  useEffect(()=>{

    let newSects = sections.map(section => {
      return {
        id: section.id,
        title: section.title,
        top: section.ref.offsetTop,
        bottom: section.ref.offsetTop + section.ref.offsetHeight
      }
    })

    setSects(newSects)

    if(sections.length > 0)
    setTopOffset(sections[0].ref.offsetTop / 3)

  },[sections, setSects, setTopOffset])

  useEffect(()=>{
    setSects(sects => {
      let sections = [...sects]

      sections = sections.map(section => {
        let progress = 0

        progress = invLerp(section.top, section.bottom, scrollY + 150)

        progress = progress*100

        return { ...section, progress:progress }
      })


      return sections
    })
  }, [scrollY, setSects])

  return (
    <nav
      className={`${inPageNavHolder} ${(scrollY < topOffset) ? 'absolute' : 'fixed'}`}
      style={(scrollY < topOffset) ? {top: topOffset} : { top:0 }}>
      <ul>
      {
        sects?.map(section => {

          return (
            <li key={section.id}>
              <div className="link">
                <div>
                  <AnchorLink to={`/${pageTitle}/#${section.id}`} stripHash={false}>{section.title}</AnchorLink>
                  <ProgressBar progress={section.progress} /> 
                </div>
                <div className="ohm"><LogoOhm /></div>
              </div>

            </li>
          )
        })
      }
      </ul>
    </nav>
  )
}
